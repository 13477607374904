@import '../vars';


.filter-fixed-button {
    display: none;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100px;
    padding: 20px;
    background: transparent !important;
    border: none !important;
}


@media (max-width: 992px) {
    .filter-fixed-button {
        display: block;
    }
}