@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

/*================================================
                Default CSS
=================================================*/

body {
    height: 100vh;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    outline: 0 !important;
    color: var(---heading-color);
}

a:hover {
    text-decoration: none;
    color: var(--main-theme-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

h1 {
    font-size: 80px;
    font-weight: bold;
}

h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
}

h3 {
    font-size: 24px;
    font-weight: 500;
}

h4 {
    font-size: 22px;
    font-weight: 600;
}

h5 {
    font-size: 18px;
    font-weight: 400;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

p {
    font-size: 16px;
    line-height: 24px;
    color: var(--paragraph-color);
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

p:last-child {
    margin-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    padding: 0;
}

/* --Common Class Area start-- */

.box-shadow {
    -webkit-box-shadow: 0px 3px 18px 7px #5b6fe612;
    box-shadow: 0px 3px 18px 7px #5b6fe612;
    border-radius: 20px;
}

.border-radius {
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
}

.bg-color {
    background-color: var(--dark-bg-color) !important;
}

.section-title-center h3 {
    color: var(--main-theme-color);
}

.left-side-title h3 {
    color: var(--main-theme-color);
}

.m0 {
    margin: 0px;
}

.mt4 {
    margin-top: 4%;
}

.mb4 {
    margin-bottom: 4%;
}

.pt40 {
    padding-top: 40px;
}

.pt30 {
    padding-top: 30px;
}

.pt20 {
    padding-top: 20px;
}

.py100 {
    padding: 100px 0;
}

.py50 {
    padding: 50px 0;
}

.py40 {
    padding: 40px 0;
}

.py20 {
    padding: 20px 0;
}

.py10 {
    padding: 10px 0;
}

.pl20 {
    padding-left: 20px;
}

.pb24 {
    padding-bottom: 24px;
}

.pb12 {
    padding-bottom: 12px;
}


.align-center {
    display: flex;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.center-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*================================================
                    Buttons CSS
=================================================*/

.btn {
    position: relative;
    display: inline-block;
    padding: 0 35px;
    font-size: 18px;
    line-height: 45px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    border-radius: 40px;
}

.btn-theme {
    color: #fff;
    background: var(--main-theme-color);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
}

.btn-theme:hover {
    color: var(--main-theme-color);
    background: var(--heading-color);
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    -o-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
}