@import '../vars';

.btn-applicants {
    font-size: 14px !important;
    padding: 17px 14px !important;
    line-height: 0px !important;
}

.btn-see-more {
    color: var(--main-theme-color) !important;
    background: #fff !important;
    text-decoration: underline !important;
    font-size: 14px !important;
    padding: 17px 14px !important;
    line-height: 0px !important;
    border: none;
}


/* --------------------------
Blog Grid Page
-----------------------------*/
#blog-grid-area .blog-items-home4 {
    margin-bottom: 30px;
}

/* blog Area start */
.blog-items-home4 {
    background: #ffffff;
    border-radius: 10px;
    transition: .4s all ease-in-out;
}

.blog-items-home4:hover {
    transform: translateY(-10px);
}

.blog-imgs-home4 {
    position: relative;
    z-index: 1;
}

.blog-imgs-home4 a {
    display: block;
}

.blog-imgs-home4 a img {
    border-radius: 10px 10px 0 0;
    max-height: 300px;
    width: 100%;
    object-fit: cover;
}

.dats-area-home4 {
    position: absolute;
    left: 30px;
    bottom: -30px;
    text-align: center;
    background: #ffffff;
    padding: 15px 30px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 27px;
    box-shadow: 0 0 10px #cccccc;
    border-radius: 5px;
}

.blog-home4-info {
    padding: 30px;
    padding-top: 55px;
    position: relative;
    z-index: 3;
}

.meta-area {
    margin-bottom: 10px;
}

.meta-area ul li:first-child {
    font-size: 16px;
}

.meta-area ul {
    display: flex;
    align-items: center;
    text-align: center;
}

.meta-area ul li {
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    margin-right: 7px;
    position: relative;
    z-index: 1;
}

.meta-area ul li a {
    display: inline-block;
    font-weight: 500;
    color: #666666;
    font-size: 14px;
}

.blog-home4-info h4 {
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 500;
}

/* blog Area End */

/* --------------------------
Blog Page
-----------------------------*/

.my-jobs-header {
    padding: 0px 0px 0px 10px;
}

.my-jobs-header h4 {
    display: flex;
    margin-top: 1%;
    align-items: center;
}

.my-jobs-header div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left-blog-page {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 25px;
}

.blog-search-option {
    display: block;
    background: #fff;
    border: 1px solid var(--main-theme-color);
    border-radius: 3px;
}

.blog-search-option input {
    padding: 5px 15px;
    width: 80%;
    border: none;
    height: 56px;
    background: transparent;
}

.blog-search-option button {
    background: transparent;
    border: none;
}

.blog-search-option button i {
    line-height: 16px;
    font-size: 18px;
}

.left-blog {
    overflow: hidden;
    padding-bottom: 20px;
}

.left-blog h4 {
    position: relative;
    color: #444;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 15px 0px;
    text-transform: capitalize;
}

.left-blog h4::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: var(--main-theme-color);
}

.left-blog li {
    display: block;
}

.left-blog ul li a {
    display: inline-block;
    padding: 7px 0px 7px 0px;
    text-transform: capitalize;
    position: relative;
}

.left-blog ul li a:hover {
    color: var(--main-theme-color);
}

.left-blog ul li span {
    float: right;
    border: 1px solid var(--main-theme-color);
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50px;
    color: var(--main-theme-color);
    line-height: 24px;
}

.blog-tags {
    padding: 1px 0;
}

.left-blog {
    overflow: hidden;
    padding-bottom: 20px;
}

.left-side-tags h4 {
    margin-bottom: 15px;
}

.popular-tag.left-side-tags.left-blog ul {
    padding: 0px;
}

.left-tags .left-side-tags ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 3px;
}

.left-tags .left-side-tags ul li a {
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 3px;
    font-weight: 400;
}

.left-tags .left-side-tags ul li a:hover {
    color: #fff;
    background: var(--main-theme-color);
}

/* Job cart */
.job-cart {
    border-radius: 25px;
}

.job-header img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 0.5px var(--paragraph-color);
    object-fit: cover;
}

.applicant-number {
    position: absolute;
    left: 0;
    bottom: -22px;
    text-align: center;
    background: #ffffff;
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 27px;
    box-shadow: 0 0 10px #cccccc;
    border-radius: 5px;
}

.job-title {
    display: block;
    padding: 10px;
    margin-top: auto;
}

.more-information button {
    background-color: var(--banner-paragraph-color);
}

.more-information img {
    display: none;
}

.job-content ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 3px;
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 4px;
    font-weight: 400;
}

.job-footer {
    margin-top: 2%;
    border-top: solid 1px #e8e8e8;
}

.job-footer :nth-child(1) i {
    margin-right: 4px;
}

.job-footer :nth-child(2) {
    margin-bottom: 0px;
}

.dropdown-menu {
    transform: translate3d(8px, 32px, 0px) !important;
    z-index: 999;
    min-width: 0;
}

.roundedFixedBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    line-height: 80px;
    width: 60px;
    font-size: 2em;
    font-weight: bold;
    border-radius: 50%;
    background-color: var(--main-theme-color);
    color: white;
    text-align: center;
    cursor: pointer;
}

.roundedFixedBtn img {
    width: 30px;
}


@media (max-width: 992px) {
    .job-filters {
        display: none;
    }
}

@media (max-width: 768px) {
    .btn {
        font-size: 14px;
        padding: 17px 14px;
        line-height: 0px;
    }

    .more-information button {
        display: none;
    }

    .job-header {
        padding: 10px 0px;
    }

    .more-information img {
        display: block;
        border: none;
        width: 20px;
        height: 20px;
    }

    .job-header :nth-child(3) {
        display: flex;
        align-items: center;
        padding: 1px;
    }

    h6,
    li,
    p {
        font-size: 14px;
    }

}

@media (max-width: 550px) {
    .job-content p {
        display: none;
    }

    .applicant-number p {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .apply-button button {
        line-height: 15px;
        padding: 12px 25px;
    }

    .btn-applicants {
        font-size: 12px !important;
        padding: 4px !important;
        line-height: 15px !important;
    }
    
    .btn-see-more {
        font-size: 12px !important;
    }

    .job-header {
        padding: 10 0px;
    }

    .job-header img {
        width: 50px;
        height: 50px;
    }

    .more-information img {
        width: 20px;
        height: 20px;
    }

    .meta-area p {
        font-size: 10px;
    }

    .applicant-info h4 {
        margin-bottom: 0px;
    }

    h4 {
        font-size: 16px;
    }

    h6,
    li,
    p {
        font-size: 12px;
    }
}