@import '../vars';

/* --------------------------
Blog Grid Page
-----------------------------*/
#blog-grid-area .blog-items-home4 {
    margin-bottom: 30px;
}

/* --------------------------
Blog Page
-----------------------------*/

.left-blog-page {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 25px;
}

.blog-search-option {
    display: block;
    background: #fff;
    border: 1px solid var(--main-theme-color);
    border-radius: 3px;
}

.blog-search-option input {
    padding: 5px 15px;
    width: 80%;
    border: none;
    height: 56px;
    background: transparent;
}

.blog-search-option button {
    background: transparent;
    border: none;
}

.blog-search-option button i {
    line-height: 16px;
    font-size: 18px;
}

.left-blog {
    overflow: hidden;
    padding-bottom: 20px;
}

.left-blog h4 {
    position: relative;
    color: #444;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 15px 0px;
    text-transform: capitalize;
}

.left-blog h4::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: var(--main-theme-color);
}

.left-blog li {
    display: block;
}

.left-blog ul li a {
    display: inline-block;
    padding: 7px 0px 7px 0px;
    text-transform: capitalize;
    position: relative;
}

.left-blog ul li a:hover {
    color: var(--main-theme-color);
}

.left-blog ul li span {
    float: right;
    border: 1px solid var(--main-theme-color);
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50px;
    color: var(--main-theme-color);
    line-height: 24px;
}

.blog-tags {
    padding: 1px 0;
}

.left-side-tags h4 {
    margin-bottom: 15px;
}

.popular-tag.left-side-tags.left-blog ul {
    padding: 0px;
}

.left-tags .left-side-tags ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 3px;
}

.left-tags .left-side-tags ul li a {
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 3px;
    font-weight: 400;
}

.left-tags .left-side-tags ul li a:hover {
    color: #fff;
    background: var(--main-theme-color);
}

/* Job cart */
.job-cart {
    border-radius: 25px;
}

.job-header {
    padding: 10px 0px;
}

.job-header img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 0.5px var(--paragraph-color);
    object-fit: cover;
}

.job-title {
    display: block;
    padding: 10px;
    margin-top: auto;
}

.more-information button {
    background-color: var(--banner-paragraph-color);
}

.more-information i {
    display: none;
}

.job-content ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 3px;
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 4px;
    font-weight: 400;
}

.job-footer {
    margin-top: 2%;
    border-top: solid 1px #e8e8e8;
}

.job-footer :nth-child(1) i {
    margin-right: 4px;
}

.job-footer :nth-child(2) {
    margin-bottom: 0px;
    text-align: center;
    line-height: 20px;
}

.roundedFixedBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    line-height: 80px;
    font-size: 2em;
    font-weight: bold;
    border-radius: 50%;
    background-color: var(--main-theme-color);
    color: white;
    text-align: center;
    cursor: pointer;
}

.roundedFixedBtn i {
    font-size: 16px;
}


@media (max-width: 1400px) {
    .more-information button {
        line-height: 25px;
        padding: 16px;
    }
}

@media (max-width: 1200px) {
    .more-information button {
        line-height: 25px;
        padding: 16px 8px;
    }
}

@media (max-width: 992px) {
    .job-filters {
        display: none;
    }
}

@media (max-width: 768px) {
    .more-information button {
        display: none;
    }

    .job-header {
        padding: 10px 0px;
    }

    .more-information i {
        display: block;
        border: none;
        font-size: 24px;
    }

    .job-header :nth-child(3) {
        display: flex;
        align-items: center;
        padding: 1px;
    }

}

@media (max-width: 550px) {
    .job-content p {
        display: none;
    }
}

@media (max-width: 500px) {
    .apply-button button {
        line-height: 15px;
        padding: 12px 25px;
    }

    .job-header {
        padding: 10px 0px 0px;
    }

    .job-header img {
        width: 50px;
        height: 50px;
    }

    .job-cart h4 {
        font-size: 16px;
    }

    .job-cart h6,
    .job-cart li,
    .job-cart p {
        font-size: 12px;
    }

    .job-footer {
        padding: 5px !important;
    }
}