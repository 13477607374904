@import '../vars';


// ------------ Register step 1 ----------------

.register-area-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.choose-user {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 4rem 0;
}

.talent,
.company {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.user-text {
    width: 16rem;
    position: absolute;
    left: 50%;
    bottom: -3.2rem;
    transform: translate(-50%, -30%);
    padding: 0.8rem;
    background-color: #f8f8ef;
    border-radius: 6.8rem;
    text-align: center;
    transition: .4s ease-in-out;
    opacity: 1;
}

.talent h3,
.company h3 {
    padding: relative;
    color: var(--main-theme-color);
}

.talent img,
.company img {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: solid 2px var(--main-theme-color);
    object-fit: cover;
}

.already-register {
    display: flex;
    text-align: center;
}

.already-register a {
    margin-left: 12px;
    text-decoration: underline;
    color: var(--main-theme-color);
}

// -------- Login and register 2 & 3 ------------
.login-form-area {
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    padding: 40px;
    max-width: 600px;
    margin: auto;
    border-radius: 30px;
}

.form-area-heading {
    margin-bottom: 20px;
}

.social-login-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    padding: 0px 0 30px;
}

.social-login-area button {
    height: 40px;
    border-radius: 33px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 22px;
    color: #fff;
    transition: .4s all ease;
    -webkit-transition: .4s all ease;
    -moz-transition: .4s all ease;
    -ms-transition: .4s all ease;
    -o-transition: .4s all ease;
}

.social-login-area button i {
    padding-right: 5px;
}

.social-login-area button:nth-child(1) {
    background-color: #FF5733;
}

.social-login-area button:hover:nth-child(1),
.social-login-area button:hover:nth-child(2),
.social-login-area button:hover:nth-child(3) {
    background-color: #000;
}

.social-login-area button:nth-child(2) {
    background-color: #3955bc;
}

.social-login-area button:nth-child(3) {
    background-color: #1dd3f8;
}

.form-group {
    margin-bottom: 30px;
}

.login-form-area form .form-control {
    border-radius: 0;
    // background: #fff;
    border: 1px solid var(--main-theme-color);
    font-size: 14px;
    border-radius: 10px;
}

.login-button {
    text-align: center;
    padding: 30px 0;
}

.login-desc p {
    color: var(--paragraph-color);
}

.login-desc p a {
    color: var(--main-theme-color);
}

.password-remember input {
    margin-right: 8px;
}

.password-remember a {
    color: var(--main-theme-color);
}

.update-profile-button {
    padding: 30px 0px 10px;
    text-align: center;
}

.omit-button-container {
    display: flex;
    justify-content: center;
}

.omit-button-container a {
    display: flex;
    color: var(--main-theme-color);
    justify-content: end;
    text-decoration: underline;
    padding: 0;
}

.omit-button-container a:hover {
    color: var(--main-theme-color);
}


// --------- Recover password page -------------
#recover-area .form-area-heading {
    padding-bottom: 30px;
}

#recover-area .login-button {
    text-align: center;
    padding: 0px 0;
}


@media (max-width: 1200px) {

    .talent img,
    .company img {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 770px) {
    .choose-user {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        margin: 2rem 0;
    }

    .talent img,
    .company img {
        width: 200px;
        height: 200px;
    }

    .talent {
        margin-bottom: 3rem;
    }

    .user-text {
        width: 14rem;
        padding: 0.4rem;
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }

    .already-register {
        margin-top: 2rem;
    }

}