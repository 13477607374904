@import '../vars';

.new-job-form-area {
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
    padding: 40px;
    max-width: 600px;
    margin: auto;
    border-radius: 30px;
}

.form-area-heading {
    margin-bottom: 20px;
}

.new-job-form-area form .form-control {
    border-radius: 0;
    background: #fff;
    border: 1px solid var(--main-theme-color);
    font-size: 14px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.new-job-button {
    text-align: center;
    padding: 30px 0;
}

.border-top {
    border-top: 1px solid var(--main-theme-color);
    padding-top: 12px;
}