:root {
    // --main-theme-color: #5b6fe6;
    // --dark-bg-color: #f8f9ff;
    // --heading-color: #2c3552;
    // --paragraph-color: #777c87;
    // --banner-paragraph-color: #344a6f;
    --main-theme-color: #0a44a5;
    --dark-bg-color: #f8f9ff;
    --heading-color: #ffb701;
    --paragraph-color: #777c87;
    --banner-paragraph-color: #344a6f;
}
