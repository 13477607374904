@import '../vars';

.mobile-menu {
    background-color: white;
    position: fixed;
    height: 100vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding: 24px;
    z-index: 999;
    top: 0;
    left: 0;
}

.lateral-navbar-go-out {
    position: absolute;
    right: 34px;
    top: 44px;
    cursor: pointer;
}

.mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 24px 0 0 0;
}

.lateral-navbar-img img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 0.5px var(--paragraph-color);
    object-fit: cover;
    margin-bottom: 12px;
}

.lateral-navbar-view-profile {
    color: var(--paragraph-color);
    margin-bottom: 16px;
}

.lateral-navbar {
    border-bottom: 1px solid var(--paragraph-color);
}

.mobile-menu-list li {
    margin: 10px;
}

.mobile-menu-list li a {
    text-decoration: none;
    color: var(--black);
    font-weight: 500;
}

.mobile-menu ul:nth-child(4) {
    margin-bottom: 10px;
    border-top: 0.5px solid var(--paragraph-color);
}

.mobile-menu-sign-out {
    padding: 10px 0;
}


.mobile-menu-sign-out a {
    font-weight: bold;
    color: var(--heading-color);
}

@media (min-width: 992px) {
    .mobile-menu {
        display: none;
    }
}