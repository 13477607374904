@import '../vars';

/*----------------------------------------------
                    Home CSS
----------------------------------------------*/
#banner-one {
    background-image: url("https://staffing-app.s3.amazonaws.com/landing/home-bg-banner.png");
    background-repeat: no-repeat;
    background-position: top right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.home-one-banner-content h1 {
    color: var(--main-theme-color);
}

.home-one-banner-content h2 {
    font-size: 40px;
    font-weight: 400;
    padding-top: 25px;
}

.home-one-banner-content p {
    padding-top: 20px;
    color: var(--banner-paragraph-color);
}

.banner-one-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.banner-one-btn a {
    margin-right: 45px;
}

.banner-img img {
    width: 100%;
}

.home-one-banner-content h1 {
    font-size: 60px;
}

@media (max-width: 992px) {
    .banner-img img {
        display: none;
    }
}

/*----------------------------------------------
                 Service CSS
----------------------------------------------*/
#service {
    padding: 100px 0 70px 0;
}

/*----------------------------------------------
                 How it works CSS
----------------------------------------------*/
.how-it-works-title p {
    padding-top: 10px;
}

.how-it-single-item {
    position: relative;
    margin-bottom: 40px;
    padding-left: 100px;
}

.how-it-single-item:before {
    position: absolute;
    content: '';
    width: 2px;
    height: 100%;
    left: 32px;
    top: 60px;
    background: var(--main-theme-color);
}

.how-it-single-item:last-child {
    margin: 0;
}

.how-it-inner-boxed {
    padding-top: 50px;
}

.how-it-single-item:last-child::before,
.how-it-single-item:last-child::after {
    display: none;
}

.how-it-single-item:after {
    position: absolute;
    content: "\f107";
    font-size: 17px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    left: 26.5px;
    top: 100px;
    color: var(--main-theme-color);
}

.how-it-count-box {
    position: absolute;
    left: 0;
    top: 12px;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    background: #fff;
    width: 66px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    box-shadow: 0 0 0 7px #edf1fe;
    transition: all 500ms ease;
}

.how-it-single-item:hover .how-it-count-box {
    color: #fff;
    background-color: var(--main-theme-color);
}

.how-it-single-item:hover .how-items-texed h3 a {
    color: var(--main-theme-color);
}

.how-it-count-box:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 80px;
    left: -7px;
    top: -7px;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 30px 50px 0 rgba(99, 119, 238, .2);
}

.how-items-texted h3 {
    margin-bottom: 15px;
}

@media (max-width: 992px) {
    .how-it-works-imgs {
        display: none;
    }
}

/*----------------------------------------------
                 AboutUs CSS
----------------------------------------------*/
.tabs-about-button {
    padding-top: 15px;
}


.about-tabs img {
    width: 430px;
}

.pr10 {
    padding-right: 10px;
}

@media (max-width: 992px) {
    .about-tabs img {
        display: none;
    }
}

@media (max-width: 500px) {
    .home-one-banner-content h1 {
        font-size: 48px;
    }
}

@media (max-width: 400px) {
    .home-one-banner-content h1 {
        font-size: 44px;
    }
}

