@import '../vars';

.btn-card {
    font-size: 14px !important;
    padding: 17px 14px !important;
    line-height: 0px !important;
}

/* --------------------------
    Applications Grid Page
-----------------------------*/
#applicant-grid-area .applicant-items {
    margin-bottom: 30px;
}

/* Application area start */
.applicant-items {
    background: #ffffff;
    border-radius: 10px;
    transition: .4s all ease-in-out;
}

.applicant-items:hover {
    transform: translateY(-10px);
}

.applicant-imgs {
    position: relative;
    z-index: 1;
}

.applicant-imgs a {
    display: block;
}

.applicant-imgs a img {
    border-radius: 10px 10px 0 0;
    max-height: 250px;
    width: 100%;
    object-fit: cover;
}

.applicant-status {
    position: absolute;
    left: 0;
    bottom: -22px;
    text-align: center;
    background: #ffffff;
    padding: 10px 20px;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 27px;
    box-shadow: 0 0 10px #cccccc;
    border-radius: 5px;
}

.applicant-info {
    padding: 40px 20px 20px 20px;
    position: relative;
    z-index: 2;
}

.meta-area {
    margin-bottom: 10px;
}

.meta-area ul li:first-child {
    font-size: 16px;
    margin-right: 8px;
}

.meta-area ul {
    display: flex;
    align-items: center;
    text-align: center;
}

.meta-area ul li {
    display: inline-block;
    text-transform: none !important;
    font-size: 13px;
    position: relative;
    z-index: 1;
}

.meta-area ul li a {
    display: inline-block;
    font-weight: 500;
    color: #666666;
    font-size: 14px;
}

.applicant-info h4 {
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 500;
}

/* Application area End */

/* --------------------------
        Filters Page
-----------------------------*/

.my-applicants-header {
    padding: 0px 0px 20px 10px;
}

.my-applicants-header h4 {
    display: flex;
    margin-top: 1%;
    align-items: center;
}

.left-applicant-page {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 25px;
}

.applicant-search-option {
    display: block;
    background: #fff;
    border: 1px solid var(--main-theme-color);
    border-radius: 3px;
}

.applicant-search-option input {
    padding: 5px 15px;
    width: 80%;
    border: none;
    height: 56px;
    background: transparent;
}

.applicant-search-option button {
    background: transparent;
    border: none;
}

.applicant-search-option button i {
    line-height: 16px;
    font-size: 18px;
}

.left-card {
    overflow: hidden;
    padding-bottom: 20px;
}

.left-card h4 {
    position: relative;
    color: #444;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 15px 0px;
    text-transform: capitalize;
}

.left-card h4::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: var(--main-theme-color);
}

.left-card li {
    display: block;
}

.left-card ul li a {
    display: inline-block;
    padding: 7px 0px 7px 0px;
    text-transform: capitalize;
    position: relative;
}

.left-card ul li a:hover {
    color: var(--main-theme-color);
}

.left-card ul li span {
    float: right;
    border: 1px solid var(--main-theme-color);
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50px;
    color: var(--main-theme-color);
    line-height: 24px;
}

.applicant-info-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.applicant-info-description {
    display: block;
}

@media (max-width: 992px) {
    .applicant-filters {
        display: none;
    }
}

@media (max-width: 768px) {
    .btn {
        font-size: 14px;
        padding: 17px 14px;
        line-height: 0px;
    }

    .applicant-info {
        padding: 32px 12px 20px 12px;
    }

    .applicant-info h4 {
        font-size: 16px;
        text-align: center;
        line-height: 20px;
    }

    .applicant-info p {
        line-height: 20px;
        font-size: 12px;
    }

    h6,
    li,
    p {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    h4 {
        font-size: 20px;
    }

    .applicant-info-description {
        display: none;
    }

    .btn-card {
        padding: 2px 12px !important;
        line-height: 16px !important;
    }

    .talent-availability-location p {
        font-size: 8px !important;
        line-height: 12px !important;
    }
}