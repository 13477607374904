@import '../vars';

.category-preferences ul {
    padding: 0px;
    margin-bottom: 4%;
}

.category-preferences ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 8px 4px;
    cursor: pointer;
}

.category-preferences ul li p {
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 3px;
    font-weight: 400;
    color: black;
}

.category-preferences ul li p:hover {
    background: var(--main-theme-color);
}

.react-time-picker__inputGroup input {
    min-width: 28px !important;
    text-align: center !important;
}

.react-time-picker__inputGroup span {
    padding: 0px 2px !important;
}

.react-time-picker__inputGroup__leadingZero {
    display: none !important;
}

// Remove arrows from numeric inputs
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}