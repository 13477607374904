@import '../../vars';

.service-box {
    background: #fff;
    padding: 30px 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 99;
}

.service-box-inner img {
    -webkit-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.service-box:hover .service-box-inner img {
    -ms-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
    color: #fff;
}

.service-box-inner h3 a {
    color: var(--heading-color);
}

.service-box:hover .service-box-inner h3 a,
.service-box:hover .service-box-inner a {
    color: var(--main-theme-color);
}

.service-box-inner p {
    padding-top: 10px;
}