@import '../vars';

// ===== Profile cart ==== //
.profile-cart {
    border-radius: 25px;
    border-radius: 25px;
    max-width: 950px;
    min-width: 950px;
}

.edit-profile-button {
    justify-content: flex-end;
    display: flex;
    padding: 2.5% 3% 0%;
}

.edit-profile-button i {
    font-size: 25px;
}

.profile-image img {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: solid 0.5px var(--paragraph-color);
    object-fit: cover;
}

.profile-cart-inside {
    padding: 0px 8px 40px;
    margin-top: auto;
}

// *
.talent-profile-header {
    margin: auto;
}

.company-profile-header {
    flex-direction: column;
    margin-top: 20px;
}

.company-profile-header h6 {
    margin-bottom: 0px;
}

.company-profile-header p {
    margin-top: 12px;
}

.company-check-icon {
    color: rgb(16, 222, 25);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    margin-bottom: 0.5rem;
}

.profile-location {
    display: flex;
    padding: 4px;
}

.profile-location i {
    margin-right: 4px;
}

.company-review {
    display: flex;
}

.company-review li {
    padding-right: 4px;
    color: var(--main-theme-color);
}

.edit-profile {
    justify-content: flex-end;
}

.edit-profile button {
    display: none;
}

.edit-job {
    justify-content: flex-end;
}

.edit-job i {
    display: none;
}

.edit-job button {
    background-color: var(--banner-paragraph-color);
}

.edit-job-experience {
    display: flex;
    justify-content: flex-end;
}

.edit-job-experience i {
    font-size: 20px;
}

.profile-header-info {
    margin-bottom: 12px;
}

.profile-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: solid 1px #e8e8e8;
}

.profile-footer div {
    width: 50%;
}

.profile-footer i {
    margin-right: 8px;
}

// ===== Talent additional info ==== //
.profile-additional-info {
    margin-top: 20px;
}

.profile-navbar {
    padding-top: 2%;
}

.edit-info {
    align-items: center;
    justify-content: flex-end;
}

.edit-info i {
    display: block;
    border: none;
    font-size: 30px;
}

.profile-about-me {
    margin: 2% 0%;
    border-bottom: solid 1px #e8e8e8;
}

.profile-about-me p {
    padding-bottom: 8px;
}

// --- Preferences --- //
.talent-profile-preferences-header {
    margin-bottom: 20px;
}

.talent-profile-preferences-header h4 {
    margin-bottom: 0px;
}

.talent-profile-preferences-header div {
    display: flex;
    justify-content: flex-end;
}

.talent-profile-my-preferences ul {
    padding: 0px;
    margin-bottom: 4%;
}

.talent-profile-my-preferences ul li {
    border-bottom: 0;
    display: inline-block;
    margin: 8px 4px;
}

.talent-profile-my-preferences ul li a {
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 3px;
    font-weight: 400;
}

.talent-profile-my-preferences ul li a:hover {
    background: var(--main-theme-color);
}

.talent-profile-jobs-header {
    padding: 12px 0px 12px 10px;
    border-bottom: solid 1px #e8e8e8;
}

.talent-profile-jobs-header h4 {
    display: flex;
    margin-top: 1%;
    align-items: center;
}

.talent-profile-jobs-header div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* ===== Company jobs ====== */

.company-jobs {
    margin-top: 20px;
}

.company-profile-jobs-header {
    padding: 20px 0px 20px 10px;
    border-bottom: solid 1px #e8e8e8;
}

.company-profile-jobs-header h4 {
    display: flex;
    margin-top: 1%;
    align-items: center;
}

.company-profile-jobs-header div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn-new-job {
    padding: 0 24px !important;
}

.job-info {
    margin-top: 4%;
    border-bottom: solid 1px #e8e8e8;
}

.company-profile-jobs-cart-inside {
    display: flex;
    justify-content: center;
    margin-top: auto;
}

.job-location {
    display: flex;
    align-items: center;
}

.job-location i {
    margin-right: 4px;
}

.job-location h6 {
    margin-bottom: 0px;
}

.job-description-2 {
    display: none;
}

.job-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: solid .45px #e8e8e8;
}

.job-footer i {
    margin-right: 8px;
}

@media (max-width: 1400px) {

    .edit-profile button,
    .edit-job button {
        line-height: 25px;
        padding: 16px;
    }
}

@media (max-width: 1200px) {

    .edit-profile button,
    .edit-job button {
        line-height: 25px;
        padding: 16px 8px;
    }
}

@media (max-width: 992px) {

    .profile-header,
    .a-header {
        margin: 24px 12px 0px 12px;
    }

    .job-info img {
        width: 120px;
        height: 120px;
    }

    .profile-cart {
        min-width: 720px;
    }
}

@media (max-width: 768px) {
    .btn {
        font-size: 14px;
        padding: 17px 14px;
        line-height: 0px;
    }

    .edit-job {
        justify-content: flex-end;
    }

    .edit-profile button,
    .edit-job button {
        display: none;
    }

    .profile-cart {
        min-width: 540px;
    }

    .profile-cart-inside {
        padding: 10px 0px;
    }

    .edit-profile {
        align-items: center;
        justify-content: center;
    }

    .edit-profile a,
    .edit-job a {
        cursor: pointer;
    }

    .edit-profile i,
    .edit-job i {
        display: block;
        border: none;
        font-size: 30px;
    }

    .profile-cart-inside:nth-child(3) {
        display: flex;
        align-items: center;
        padding: 1px;
    }

    .job-info img {
        width: 100px;
        height: 100px;
    }

    .job-description {
        display: none;
    }

    .job-description-2,
    .job-footer p,
    .job-location h6 {
        display: block;
        font-size: 12px !important;
        line-height: 20px !important;
    }
}

@media (max-width: 500px) {
    .apply-button button {
        line-height: 15px;
        padding: 12px 25px;
    }

    .profile-cart {
        min-width: 240px;
    }

    .profile-cart-inside {
        padding: 10 0px;
    }

    .profile-cart-inside img {
        width: 80px;
        height: 80px;
    }

    .edit-profile i {
        font-size: 24px;
    }

    .edit-profile-button i {
        font-size: 20px;
    }

    .job-info img {
        width: 50px;
        height: 50px;
    }

    .edit-job i {
        font-size: 20px;
    }

    .job-name h4 {
        font-size: 20px;
    }

    .company-profile-jobs-header h4,
    .btn-new-job {
        font-size: 16px !important;
    }

    .talent-profile-jobs-header h4 {
        font-size: 16px !important;
    }

    .btn-new-job {
        padding: 0 16px !important;
        line-height: 32px !important;
    }

    h6,
    li,
    p {
        font-size: 12px !important;
        line-height: 20px !important;
    }
}