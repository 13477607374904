@import '../../vars';

.navs-title {
    border-bottom: 1px solid #dee2e6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navs-title button {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.nav-item {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid var(--main-theme-color);
}

.list-content {
    display: flex;
}

.list-content img {
    width: 45px;
    height: 45px;
    margin: auto;
}

.list-content p {
    margin-left: 16px;
}